import { AuthEvents } from "@my/api"
import { EventBus } from "app/events"
import { Routes } from "app/routing"
import { useRouter, useSearchParams } from "next/navigation"

export default function HomePage() {
  const params = useSearchParams()
  if (params.get("code") && params.get("state")) {
    // After the user logs in, Auth0 redirects to "/" with code and state params present.
    EventBus.emit(AuthEvents.Login)
  }

  const router = useRouter()
  router.replace(Routes.chat)
  return null
}
